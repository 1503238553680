function fillTimeInfo(seminarId, seminarInfoDate, seminarTimeZone, language, monthFormat = "MMM") {

    var seminarLocalTimeZoned = convertDate(language, seminarInfoDate, seminarTimeZone);

    if (document.getElementById('hour-' + seminarId)) {
        document.getElementById('hour-' + seminarId).innerHTML = seminarLocalTimeZoned.format("HH:mm");
    }

    if (document.getElementById('month-' + seminarId)) {
        document.getElementById('month-' + seminarId).innerHTML = seminarLocalTimeZoned.format(monthFormat);
    }

    if (document.getElementById('day-' + seminarId)) {
        document.getElementById('day-' + seminarId).innerHTML = seminarLocalTimeZoned.format("DD");
    }
}

function convertDate(language, seminarInfoDate, seminarTimeZone) {
    moment.locale(language);
    var format = moment.localeData().longDateFormat('L');
    var seminarInfoDateTimeZoned = moment(seminarInfoDate, format + " HH:mm A").tz("Europe/Berlin", true);
    var seminarTimeZoned = moment(seminarInfoDateTimeZoned).tz(seminarTimeZone);
    var clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone != undefined ? Intl.DateTimeFormat().resolvedOptions().timeZone : seminarTimeZone;
    var seminarLocalTimeZoned = moment(seminarTimeZoned).tz(clientTimeZone);

    return seminarLocalTimeZoned;
}