function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function checkLoginModal() {
    if (window.location.href.search("/#/login?") > 0) {
        var setEmail = getParameterByName('email');
        if (setEmail != "" && setEmail != null) {
            $("#setEmail").val(setEmail);
            $("#loginModal").modal("show");
        }
    }
}

var seminarIdFromLink;
var seminarActionType;
var isSubscriptionFormActive;
var newsIdFromLink;
var presenterIdFromLink;
var redirectUrlFromLink;
var locationIdFromLink;
var singleContentIdFromLink;

// login ajax call callbacks
function refreshSuccess() {
    let destination = null;
    let isNewTab = false;
    if (seminarIdFromLink) {
        destination = getSeminarDestination();
        if (destination == null) {
            //subscribe flow
            return;
        }
    }
    else if (newsIdFromLink) {
        destination = `/${SITE_CULTURE}/news/${newsIdFromLink}/details`;
    }
    else if (presenterIdFromLink) {
        destination = `/${SITE_CULTURE}/presenters/${presenterIdFromLink}/details`;
    }
    else if (locationIdFromLink) {
        destination = `/${SITE_CULTURE}/location/${locationIdFromLink}/details`;
    }
    else if (singleContentIdFromLink) {
        destination = `/${SITE_CULTURE}/content?id=${singleContentIdFromLink}`;
    }
    else if (redirectUrlFromLink) {
        destination = redirectUrlFromLink;
        isNewTab = true;
    }
    if (destination == null || window.location.href.match(destination)) {
        window.location.reload()
        return;
    }
    if (!isNewTab) {
        window.location.href = destination;
    } else {
        window.open(destination, '_blank');
        window.location.reload()
    }
};

var seminarActionTypes = {
    WatchOnDemand: 'WatchOnDemand',
    Subscribe: 'Subscribe',
    Details: 'Details',
};

function getSeminarDestination() {
    let destination = null;

    if (seminarActionType === undefined || seminarActionType === '') {
        destination = getDestinationUrl(false);
        return destination;
    }

    if (seminarActionType === seminarActionTypes.Subscribe) {
        $('#loginModal').modal('hide');
        var isSubscribedBefore = isSubscribe();
        if (isSubscribedBefore) {
            destination = getDestinationUrl(true);
            return destination;
        } else if (isSubscriptionFormActive && isSubscriptionFormActive === 'True') {
            seminarActionType = seminarActionTypes.Details;
            openConfirmSubscription();
            return;
        } else {
            seminarActionType = seminarActionTypes.Details;
            subscribe();
            return;
        }
    }

    if (seminarActionType === seminarActionTypes.WatchOnDemand) {
        destination = getDestinationUrl(true);
        seminarActionType = null;
        return destination;
    } else {
        destination = getDestinationUrl(false);
        return destination;
    }

}
function getDestinationUrl(isWatch) {
    var destination = null;
    if (isWatch) {
        destination = `/${SITE_CULTURE}/seminars/${seminarIdFromLink}/watch`;
    } else {
        destination = `/${SITE_CULTURE}/seminars/${seminarIdFromLink}/details`;
    }
    return destination;
}

function isSubscribe() {
    var resultSubscribe;
    $.ajax({
        type: "GET",
        async: false,
        cache: false,
        timeout: 30000,
        url: "/isSubscribed",
        data: { seminarId: seminarIdFromLink },
        success: function (data) {
            resultSubscribe = data;
        }
    });
    return resultSubscribe;
}

function subscribe() {
    $.ajax({
        type: "POST",
        async: false,
        cache: false,
        timeout: 30000,
        url: '/subscribe',
        data: { seminarId: seminarIdFromLink },
        success: subscriptionSuccess,
        error: subscriptionFailed
    });
}

$(document).ready(function () {
    $('#loginModal').on('show.bs.modal', function (e) {
        if ($(e.relatedTarget)) {
            seminarIdFromLink = $(e.relatedTarget).attr('data-seminarId');
            var seminarIdQueryParam = seminarIdFromLink ? '?seminar=' + seminarIdFromLink : "";
            $("#registerLink").attr('href', $("#registerLink").attr('href') + seminarIdQueryParam);
            seminarActionType = $(e.relatedTarget).attr('data-seminarActionType');
            isSubscriptionFormActive = $(e.relatedTarget).attr('data-isSubscriptionFormActive');
            newsIdFromLink = $(e.relatedTarget).attr('data-newsId');
            presenterIdFromLink = $(e.relatedTarget).attr('data-presenterId');
            redirectUrlFromLink = $(e.relatedTarget).attr('data-redirect-url');
        }
        resetForm('#modalLoginForm');
    });
});

