
function registerSuccess(settings) {
    if (settings.redirect) {
        var redirectTo = "/";
        if (settings.seminarId != "" && settings.seminarId != null)
            redirectTo = `/${SITE_CULTURE}/seminars/${settings.seminarId}/details`;
        window.location.href = redirectTo;
    } else {
        $('#registerModal').modal('hide');
        if (settings.email == "" || settings.email == null) {
            emailCookie = readCookie("E-mail_adres");
            if (emailCookie != null)
                email = emailCookie.replace("%40", "@");
        }
        $("#registerSuccessfulModal #email").html(settings.email);
        $('#registerSuccessfulModal').modal('show');
    }    
};

function setNextButtonAttributes() {
    if ($("#countrySelectDropDownList")[0][0].selected) {
        $("#btnNext").prop('disabled', true);
    }
    else {
        $("#btnNext").prop('disabled', false);
    }
}

var current_st, next_st, previous_st;

function NextButton(msg) {
    current_st = $(this).parent()[0];
    next_st = $($(this).parent()[0]).next('.stepContainer');
    previous_st = $($(this).parent()[0]).prev('.stepContainer');
    $(".form__errorMessage").hide();

    if ($(next_st[0]).find("#ClickThis").length > 0) {
        if ($("#ClickThis").prop("checked", true) && $.isNumeric($("#Handynummer").val())) {

            $($(next_st[0]).find("#btnSkip")[0]).css({ 'display': 'none' });
            $($(next_st[0]).find(".nextButton")[0]).css({ 'display': 'block' });
        }
        else {
            $($(next_st[0]).find("#btnSkip")[0]).css({ 'display': 'block' });
            $($(next_st[0]).find(".nextButton")[0]).css({ 'display': 'none' });
        }
    }

    $(".prevButton").css({ 'display': 'block' });

    $(current_st).removeClass("show");
    $(next_st).addClass("show");

    $("#progressbar li").eq($(".stepContainer").index(next_st)).addClass("active");
    $("#progressbar li").eq($(".stepContainer").index(current_st)).removeClass("current");
    $("#progressbar li").eq($(".stepContainer").index(current_st)).addClass("finished");
    $("#progressbar li").eq($(".stepContainer").index(next_st)).addClass("current");

    current_st.animate({}, {
        step: function () {

            current_st.css({
                'display': 'none',
                'position': 'relative'
            });

            next_st.css({
                'display': 'block'
            });
        }
    });
}

function SkipButton(val) {
    current_st = $($($(val).parent()[0]).parent()[0]).parent()[0];
    next_st = $($($($(val).parent()[0]).parent()[0]).parent()[0]).next('.stepContainer');

    $(current_st).removeClass("show");
    $(next_st).addClass("show");

    $(".nextButton").css({ 'display': 'block' });


    $("#progressbar li").eq($(".stepContainer").index(next_st)).addClass("active");
    $("#progressbar li").eq($(".stepContainer").index(current_st)).removeClass("current");
    $("#progressbar li").eq($(".stepContainer").index(current_st)).addClass("finished");
    $("#progressbar li").eq($(".stepContainer").index(next_st)).addClass("current");

    current_st.animate({}, {
        step: function () {

            current_st.css({
                'display': 'none',
                'position': 'relative'
            });

            next_st.css({
                'display': 'block'
            });
        }
    });

}

if ($(".show").hasClass("first-screen")) {
    $(".prevButton").css({ 'display': 'none' });
}

function onPreviousButtonClick(val) {
    current_st = $(val).closest(".stepContainer.show");
    previous_st = current_st.prev();

    $(current_st).removeClass("show");
    $(previous_st).addClass("show");
    $(".form__errorMessage").hide();

    if ($(previous_st).find("#ClickThis").length > 0) {
        if ($("#ClickThis").prop("checked", true) && $.isNumeric($("#Handynummer").val())) {

            $($(previous_st).find("#btnSkip")[0]).css({ 'display': 'none' });
            $($(previous_st).find(".nextButton")[0]).css({ 'display': 'block' });
        }
        else {
            $($(previous_st).find("#btnSkip")[0]).css({ 'display': 'block' });
            $($(previous_st).find(".nextButton")[0]).css({ 'display': 'none' });
        }
    }

    $(".prevButton").css({ 'display': 'block' });

    if ($(".show").hasClass("first-screen")) {
        $(".prevButton").css({ 'display': 'none' });
    }

    $("#progressbar li").eq($(".stepContainer").index(current_st)).removeClass("active");
    $("#progressbar li").eq($(".stepContainer").index(current_st)).removeClass("current");
    $("#progressbar li").eq($(".stepContainer").index(previous_st)).addClass("current");


    current_st.animate({}, {
        step: function () {
            current_st.css({
                'display': 'none',
                'position': 'relative'
            });

            previous_st.css({
                'display': 'block'
            });
        }
    });
}

$('#warningModal').on('shown.bs.modal', function () {
    $('body').addClass('modal-open');
});