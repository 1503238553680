// Open external links in a popup modal notice

document.onreadystatechange = function (e) {
    if (document.readyState === 'complete') {
        updateexternallinks();
    }
};

function updateexternallinks() {
    $.expr[":"].external = function (a) {
        var linkhn = a.hostname.split('.').reverse();
        var linkHref = linkhn[1] + "." + linkhn[0];
        var domainhn = window.location.hostname.split('.').reverse();
        var domainHref = domainhn[1] + "." + domainhn[0];
        const isHistoryBackLink = a.href.match('javascript:(\s*)history.back\(\)\;{0,1}(\s*)') != null;
        return !a.href.match(/^mailto\:/) && !a.href.match(/^tel\:/) && linkHref !== domainHref && !isHistoryBackLink;
    };
    $("a:external").addClass("ext_link");
    $(function () {
        $('a.ext_link').click(function () {
            // open a modal
            $('a:external').attr('data-toggle', 'modal');
            $('a:external').attr('data-target', '#externalNavigationConfirmationModal');
            //go to link on modal close
            var url = $(this).attr('href');
            $('#continueBtn').click(function () {
                window.open(url);
                $('#continueBtn').off();
            });
            $('#closeBtn').click(function () {
                $('#externalNavigationConfirmationModal').modal('hide');
                $('#closeBtn').off();
            });
        });
    });
}