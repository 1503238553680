/*
Front-end script
*/

// Video embedding
document.addEventListener('DOMContentLoaded',
    function () {
        var up2_video_thumb = function (id) {
            var thumb = '<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg" alt="" />',
                play = '<div class="play"><div class="ytp-large-play-button ytp-button" aria-label="Play"><svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%"><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill-opacity="0.8"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg></div></div>';
            return thumb.replace('ID', id) + play;
        }, up2_video_frame = function () {
            var iframe = document.createElement('iframe');
            iframe.setAttribute('src', 'https://www.youtube.com/embed/' + this.dataset.id + '?autoplay=1');
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allowfullscreen', '1');
            this.parentNode.replaceChild(iframe, this);
        };

        var div, n,
            v = document.getElementsByClassName('youtube-player');
        for (n = 0; n < v.length; n++) {
            div = document.createElement('div');
            div.setAttribute('data-id', v[n].dataset.id);
            div.innerHTML = up2_video_thumb(v[n].dataset.id);
            div.onclick = up2_video_frame;
            v[n].appendChild(div);
        }
    }

);

function includeHTML() {
    var z, i, elmnt, file, xhttp;
    /* Loop through a collection of all HTML elements: */
    z = document.getElementsByTagName("*");
    for (i = 0; i < z.length; i++) {
        elmnt = z[i];
        /*search for elements with a certain atrribute:*/
        file = elmnt.getAttribute("w3-include-html");
        if (file) {
            /* Make an HTTP request using the attribute value as the file name: */
            xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4) {
                    if (this.status == 200) {
                        elmnt.innerHTML = this.responseText;
                    }
                    if (this.status == 404) {
                        elmnt.innerHTML = "Page not found.";
                    }
                    /* Remove the attribute, and call this function once more: */
                    elmnt.removeAttribute("w3-include-html");
                    includeHTML();
                }
            }
            xhttp.open("GET", file, true);
            xhttp.send();
            /* Exit the function: */
            return;
        }
    }

    $('[data-toggle="tooltip"]').tooltip();

    ///Logged menus
    $(".account").click(function () {
        $(".account__menu").slideToggle(300);
        $(this).toggleClass("account--active");
    });

    $(".forumMenu").click(function () {
        $(".forumMenu__open").slideToggle(600);
        $(this).toggleClass("active");
    });

    // Video pause
    $('#videoModal').on('hide.bs.modal', function () {
        $('#video1')[0].pause();
    })

    $('.toggle-password').click(function () {
        $(this).find('.form__passwordBtn').toggleClass('form--passwordBtnInvisible form--passwordBtnVisible');
        var input = $('#' + $(this).attr("toggle"));
        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }

    });

    // Video play button
    $('#play').on('click', function (e) {
        e.preventDefault();
        $("#player")[0].src += "?autoplay=1";
        $('#player').show();
        $('#video-cover').hide();
        $('#play').hide();
    });

    // Forgot pass modal.
    $('#modalForgotPassForm').on('shown.bs.modal', function () {
        $('body').addClass('modal-open');
    });
}


$(document).ready(function () {

    checkLoginModal()
    initSuccessfullModal();

    if ($('.facultyCarousel__slider').length) {
        // Slider
        var slider = tns({
            container: '.facultyCarousel__slider',
            items: 1,
            slideBy: 'page',
            mouseDrag: true,
            mode: 'carousel',
            navPosition: 'bottom',
            loop: false,
            autoplay: false,
            swipeAngle: false,
            speed: 400,
            controlsContainer: "#customize-controls",
            responsive: {
                320: {
                    items: 1
                },
                768: {
                    items: 2
                },
                900: {
                    items: 2
                },
                1100: {
                    items: 3
                }
            }
        });
    }

    if ($(next_st) != null) {
        $("#ClickThis,#Handynummer").on("change", function () {
            if (this.checked && $.isNumeric($("#Handynummer").val())) {
                $($(next_st[0]).find("#btnSkip")[0]).css({ 'display': 'none' });
                $($(next_st[0]).find(".nextButton")[0]).css({ 'display': 'block' });
            }
            else {
                $($(next_st[0]).find("#btnSkip")[0]).css({ 'display': 'block' });
                $($(next_st[0]).find(".nextButton")[0]).css({ 'display': 'none' });
            }
        });
    }
});

// Video embedding
document.addEventListener('DOMContentLoaded',
    function () {
        var up2_video_thumb = function (id) {
            var thumb = '<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg" alt="" />',
                play = '<div class="play"><div class="ytp-large-play-button ytp-button" aria-label="Play"><svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%"><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill-opacity="0.8"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg></div></div>';
            return thumb.replace('ID', id) + play;
        }, up2_video_frame = function () {
            var iframe = document.createElement('iframe');
            iframe.setAttribute('src', 'https://www.youtube.com/embed/' + this.dataset.id + '?autoplay=1');
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allowfullscreen', '1');
            this.parentNode.replaceChild(iframe, this);
        };

        var div, n,
            v = document.getElementsByClassName('youtube-player');
        for (n = 0; n < v.length; n++) {
            div = document.createElement('div');
            div.setAttribute('data-id', v[n].dataset.id);
            div.innerHTML = up2_video_thumb(v[n].dataset.id);
            div.onclick = up2_video_frame;
            v[n].appendChild(div);
        }
    }

);

function showErrorMsg(res) {
    let $errorMsg = $(".form__errorMessage");
    setDisableSubmitButton(false);
    let errorMsgText = res.readyState == 0 ? "No internet connection." : res.responseText;
    if (res.status == 401) {
        $("#allowMsg").html(errorMsgText);
        $("#registerModal").modal('hide');
        $("#AllowModal").modal('show');
    }
    else {
        $errorMsg.html(errorMsgText);
        $errorMsg.show();
    }
};

$("#resetData").click(function () {
    $(this).closest('form').find("input[type=password], text").val("");
});

$("#resetToDefault").click(function () {
    $(this).closest('form').find("input[type=text], textarea, select").each(function () {
        if ($(this).is('select')) {
            $(this).val($(this).find('option[selected]').val());
        } else {
            $(this).val(this.defaultValue);
        }
    });;
});

function changePasswordError() {
    let $errorMsg = $(".errorPassword");
    $errorMsg.html("Current password not correct. Please enter the correct password.");
    $errorMsg.show();
};

// confirm subscribe to seminar ajax call success callback
function confirmSubscriptionSuccess(res) {
    // Add response in Modal body
    $('.container-modal-body').html(res);
    
    // Display Modal Container
    $('#modalContainer').modal('show');
    
    // Refresh page to show correct status
    $('#closeBtnSubscribeSuccess, #closeIconSubscribeSuccess').on('click', refreshSuccess);
}

// confirm subscribe to seminar ajax call failure callback
function confirmSubscriptionFailed(res) {
    $("#modalContainer").modal('hide');
    subscriptionFailed(res);
}

function setDisableSubmitButton(status) {
    $("#btnSubmit").prop('disabled', status);
}

// subscribe to seminar ajax call callback
function subscriptionFailed(res) {
    setDisableSubmitButton(false);
    if (res.status === 401) {
        $("#allowMsg").html(res.responseText);
        $("#AllowModal").modal('show');
    }

    if (res.status === 400) {
        if (res.responseText === "BUNDLE_LIMIT_REACHED") {
            $("#bundleLimitModal").modal('show');
        } else if (res.responseText === "MAX_USERS_LIMIT_REACHED") {
            $("#maxUsersLimitReachModal").modal('show');
        } else {
            $("#subscribeFailureModal").modal('show');
        }
    }
};

function subscriptionSuccess() {
    $("#subscribeSuccessModal").modal('show');

    $('#closeBtnSubscribeSuccess, #closeIconSubscribeSuccess').on('click', refreshSuccess);
};

$(".buttonShare__icon").click(function () {
    popupModal(this.href, this.title, 600, 600);
    return false;
});

function popupModal(url, title, w, h) {
    var left = (screen.width / 2) - (w / 2);
    var top = (screen.height / 2) - (h / 2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
};

// init reset password ajax call callbacks
function initResetPasswordSuccess(email) {
    $("#resetPasswordModal").modal('hide');
    $("#passwordResetSuccessfulModal #email").html(email);
    $('#passwordResetSuccessfulModal').modal('show');
};

function DeleteAccountSuccess() {
    $("#deleteAccountModal").modal('hide');
    $('#confirmDeleteAccount').modal('show');
}

$("#btnReset").click(function () {
    $("input:checked").prop('checked', false);
});

function userNotificationsSuccess() {
    $('#saveChangesModal').modal('show');
};

function userOptOutSuccess() {
    $('#optOut').modal('hide');
    $('#saveChangesModal').modal('show');

    $('#btnSaveChangesModal').on('click', function () {
        window.location.href = '/';
    })
};

function UnsubscriptionFailed(res) {
    if (res.status === 401) {
        window.location.reload();
        return;
    }

    if (res.status === 400) {
        alert("Cannot unsubscribe to this seminar.");
    }
};

function resetForm(selector) {
    // clear validation errors
    $('.field-validation-error').empty();
    $('.form__errorMessage').hide();

    // reset form
    $(selector)[0].reset();
}

// Video play button
$('#play').on('click', function (e) {
    e.preventDefault();
    $("#player")[0].src += "?autoplay=1";
    $('#player').show();
    $('#video-cover').hide();
    $('#play').hide();
});

if ($("#ExternalLogin").length > 0) { $("#registerModal").modal('show'); }

if ($("#ConnectForm").length > 0) { $("#createAccount").modal('show'); }

if ($("#cookieReminder").length > 0) { $("#cookieReminder").modal('show'); }

$(document).ready(function () {

    $('#resetPasswordModal, #registerModal,#createAccount,#failedAccount,#loginModal,#cookieReminder').on('shown.bs.modal', function () {
        $('body').addClass('modal-open');
    });

    $('#registerModal').on('show.bs.modal', function () {
        resetForm('#modalRegisterForm');
    });

    $("#createAccount").modal('show');

    $('#resetPasswordModal').on('show.bs.modal', function () {
        resetForm('#modalForgotPassForm');
    });

    $(".modalLogin__form input").on('input', function () {
        $(".form__errorMessage").hide();
    });

    $("#modalRegister__form input").on('input', function () {
        $(".form__errorMessage").hide();
    });

    $("#resetPasswordForm input, #activateAccountForm input").on('input', function () {
        $(".profile__errorSummary").hide();
    });

    $('#registerModal').modal({ backdrop: 'static' });

    $('.toggle-password').click(function () {
        $(this).find('.form__passwordBtn').toggleClass('form--passwordBtnInvisible form--passwordBtnVisible');
        var input = $('#' + $(this).attr("toggle"));
        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }

    });
    $('[data-toggle="tooltip"]').tooltip();

    ///Logged menus
    $(".account").click(function () {
        $(".account__menu").slideToggle(300);
        $(this).toggleClass("account--active");
    });

    $(".forumMenu").click(function () {
        $(".forumMenu__open").slideToggle(600);
        $(this).toggleClass("active");
    });

    // Video pause
    $('#videoModal').on('hide.bs.modal', function () {
        $('#video1')[0].pause();
    })

    if ($('.memberSlider').length) {
        // Slider
        var slider = tns({
            container: '.memberSlider',
            items: 1,
            slideBy: 'page',
            mouseDrag: true,
            mode: 'carousel',
            navPosition: 'bottom',
            loop: false,
            autoplay: false,
            swipeAngle: false,
            speed: 400,
            controlsContainer: "#customize-controls",
            responsive: {
                320: {
                    items: 1
                },
                768: {
                    items: 2
                },
                900: {
                    items: 2
                },
                1100: {
                    items: 3
                }
            }
        });
    }

    // filter
    let $facultyFilterInput = $('.facultyPage .filter__input');
    let $filterableElements = $("[data-filter]");
    let $facultyOverviewNoResults = $(".facultyGridSecondary__noResults");

    $facultyOverviewNoResults.hide();

    function filterContent() {
        let searchTerm = $facultyFilterInput.val().toLowerCase();

        if (searchTerm.length > 2) {
            let searchAttrSelector = "[data-filter*='" + searchTerm + "']";
            $filterableElements.not(searchAttrSelector).hide();
            let $matchingElements = $(searchAttrSelector);
            $matchingElements.show();

            // hide/show no presenters found text
            if ($matchingElements.length == 0) $facultyOverviewNoResults.show();
            else $facultyOverviewNoResults.hide();
        } else {
            $filterableElements.show();
            $facultyOverviewNoResults.hide();
        }
    }

    // trigger filter if page loaded with populated filter
    if ($facultyFilterInput.val()) {
        filterContent();
    }

    // trigger filter when typing
    $facultyFilterInput.keyup(function (e) {
        filterContent();
    });

    // prevent post back enter
    $facultyFilterInput.keydown(function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });

    //Checkbox -  must be true validator
    $.validator.addMethod('mustbetrue',
        function (value, element, params) {

            if (element.getAttribute('data-val-required')) {

                return $(element).is(":checked");
            }
            else {

                return true;
            }
        });

    $.validator.unobtrusive.adapters.add('mustbetrue',
        function (options) {
            options.rules['mustbetrue'] = options.params;
            if (options.message) {
                options.messages["mustbetrue"] = options.message;
            }
        });
   
    //Seacrh on faculty member
    var routeval = "presenters";
    if ($("#routeVal")) { routeval = $("#routeVal").val(); }

    $('.filter__input').keyup(function (e) {
        let searchTerm = $('.filter__input').val().toLowerCase();
        history.replaceState({}, "filterData", searchTerm ? routeval + "?filter=" + searchTerm : routeval);
    });
});

//transform icon
$(".form__select").click(function () {
    $(this).next(".form__iconSelect").toggleClass("form__iconSelect--up");
});
$(".form__select").on("blur", function () {
    $(this).next(".form__iconSelect").removeClass("form__iconSelect--up");
});


function openConfirmSubscription(body) {
    var seminarId = $(body).data('seminarId') || seminarIdFromLink;
    $.ajax({
        url: '/confirm-subscription',
        type: 'get',
        data: { seminarId: seminarId },
        success: function (response) {
            // Add response in Modal body
            $('.container-modal-body').html(response);

            // Display Modal
            $('#modalContainer').modal('show');
        }
    });
}

$(function () {

    $('body').on('click', '#confirmSubscriptionButton', function (e) {
        e.preventDefault();
        openConfirmSubscription(this);
    });
});