function initSuccessfullModal() {
    $('#saveChangesModal').on('hidden.bs.modal', function () {
        $('#changePassword').trigger("reset");
    });
} 

function changePasswordSuccess() {
    $('#saveChangesModal').modal('show');

    $('#btnSaveChangesModal').on('click', function () {
        window.location.reload();
    })
};

function changePasswordError() {
    let $errorMsg = $(".errorPassword");
    $errorMsg.html("Current password not correct. Please enter the correct password.");
    $errorMsg.show();
};

function editRegisterSuccess() {
    $('#saveChangesModal').modal('show');
    setDisableSubmitButton(false);
};